import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import api from "./services/WebService";
import helperService from "./services/HelperService";
import VueSidebarMenu from "vue-sidebar-menu";
import store from "./store/store";
import "element-plus/dist/index.css";
import "./services/ValidationRules";
import ElementPlus from "element-plus";
import JsonExcel from "vue-json-excel3";
import vuescroll from "vuescroll";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueSidebarMenu);
app.use(vuescroll, {
  ops: {
    bar: {
      showDelay: 5000,
      onlyShowBarOnScroll: false,
      keepShow: false,
      background: "#fdac4f",
      opacity: 1,
      specifyBorderRadius: false,
      minSize: 0,
      size: "10px",
      disable: false,
    },
  },
});
app.use(ElementPlus);
app.component("downloadExcel", JsonExcel);
app.mount("#app");

app.config.globalProperties.$helperService = helperService;
app.config.globalProperties.$api = api;

app.directive("click-outside", {
  mounted(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});
