import moment from "moment";

class HelperService {
  constructor() {
    this.accessLevel = new Map();
  }

  sorting(sortBy, id) {
    var orderBy = "ASC";
    var className = "sorting_asc";
    var temp = document.getElementById(id);
    if (temp) {
      if (temp.className.includes("sorting_asc")) {
        className = "sorting_desc";
      }
      if (className == "sorting_asc") {
        temp.classList.add("sorting_asc");
        temp.classList.remove("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        temp.classList.add("sorting_desc");
        temp.classList.remove("sorting_asc");
        orderBy = "DESC";
      }
    }
    var obj = {
      order_by: orderBy,
      sort_by: sortBy,
    };
    return obj;
  }

  getFormatedDate(d) {
    return moment(d).format("MM/DD/YYYY");
  }

  getFormatedDateAndTime(dt) {
    return moment.utc(dt).local().format("DD MMM YYYY, hh:mm A");
  }

  sendFormatedDate(d, type) {
    if (type == "date") {
      return moment(d).format("YYYY-MM-DD");
    } else if (type == "month_year") {
      return moment(d).format("YYYY-MM");
    } else if (type == "year") {
      return moment(d).format("YYYY");
    } else {
      return moment(d).format("YYYY-MM-DD");
    }
  }

  allowOnlyNumericValue(e) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }
  }

  getTitleCase(value) {
    if (!value) {
      return "";
    } else {
      var str = "";
      var arr = value.split("_");
      for (var i in arr) {
        if (i == 0) {
          str =
            arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
        } else {
          str +=
            "" +
            arr[i].charAt(0).toUpperCase() +
            arr[i].substring(1).toLowerCase();
        }
      }
      return str;
    }
  }

  setAccessLevel(accessLevel) {
    this.accessLevel = accessLevel;
  }

  getAccessLevel(moduleName) {
    if (this.accessLevel.length > 0) {
      for (var i = 0; i < this.accessLevel.length; i++) {
        if (moduleName == this.accessLevel[i].name) {
          return this.accessLevel[i];
        }
      }
    }
  }

  hasAccessLevel() {
    return this.accessLevel.length > 0;
  }
}

export default new HelperService({});
