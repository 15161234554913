import { createWebHistory, createRouter } from "vue-router";
import { defineAsyncComponent } from "vue";

const MainView = defineAsyncComponent(() => import("@/views/main.vue"));
const Login = defineAsyncComponent(() => import("@/views/Login/login.vue"));
const Dashboard = defineAsyncComponent(() =>
  import("@/views/Dashboard/dashboard.vue")
);
const DashboardDetails = defineAsyncComponent(() =>
  import("@/views/Dashboard/dashboard-details.vue")
);

const Profile = defineAsyncComponent(() => import("@/views/Profile/profile"));
const PermitRenewals = defineAsyncComponent(() =>
  import("@/views/Notification/permit-renewals")
);
const ExpiredPermits = defineAsyncComponent(() =>
  import("@/views/Notification/ExpiredPermits")
);
const PendingApplication = defineAsyncComponent(() =>
  import("@/views/Notification/pending-applications")
);
const RejectedApplication = defineAsyncComponent(() =>
  import("@/views/Notification/rejected-applications")
);
const GarageAlerts = defineAsyncComponent(() =>
  import("@/views/Notification/garage-alerts")
);
const ResidentAccount = defineAsyncComponent(() =>
  import("@/views/AccountManagement/resident-account")
);
const BusinessAccount = defineAsyncComponent(() =>
  import("@/views/AccountManagement/business-account")
);
const TemporaryAccount = defineAsyncComponent(() =>
  import("@/views/AccountManagement/temporary-account")
);
const PendingAccount = defineAsyncComponent(() =>
  import("@/views/AccountManagement/pending-account")
);
const ParkingPermits = defineAsyncComponent(() =>
  import("@/views/PermitManagement/parking-permits")
);
const GaragePermits = defineAsyncComponent(() =>
  import("@/views/PermitManagement/garage-permits")
);
const BusinessPermits = defineAsyncComponent(() =>
  import("@/views/PermitManagement/business-permits")
);
const TNPSigns = defineAsyncComponent(() =>
  import("@/views/PermitManagement/TNP-signs")
);
const TNPSignMap = defineAsyncComponent(() =>
  import("@/views/PermitManagement/TNP-sign-map")
);
const Residents = defineAsyncComponent(() =>
  import("@/views/PermitManagement/Virtual-Permits/residents")
);
const Business = defineAsyncComponent(() =>
  import("@/views/PermitManagement/Virtual-Permits/business")
);
const ParkingEnforcement = defineAsyncComponent(() =>
  import("@/views/Operations/parking-enforcement")
);
const PermitLookup = defineAsyncComponent(() =>
  import("@/views/Operations/permit-lookup")
);
const ALPRLookup = defineAsyncComponent(() =>
  import("@/views/Operations/alpr-lookup")
);
const TNPOutcall = defineAsyncComponent(() =>
  import("@/views/Operations/tnp-outcall")
);
const TNPSignLookup = defineAsyncComponent(() =>
  import("@/views/Operations/tnp-sign-lookup")
);
const PEOAssignment = defineAsyncComponent(() =>
  import("@/views/Operations/peo-assignment")
);
const TNPViolations = defineAsyncComponent(() =>
  import("@/views/Operations/tnp-violations")
);
const VTPActivation = defineAsyncComponent(() =>
  import("@/views/Operations/vtp-activation")
);
const EmergencyParking = defineAsyncComponent(() =>
  import("@/views/Operations/emergency-parking")
);
const FindPEOLocation = defineAsyncComponent(() =>
  import("@/views/Operations/find-peo-location")
);
const ParkingPaymentsLookup = defineAsyncComponent(() =>
  import("@/views/AuditReports/parking-payments-lookup")
);
const PEOSearchHistoryLookup = defineAsyncComponent(() =>
  import("@/views/AuditReports/peo-search-history-lookup")
);
const ParkingViolationLookup = defineAsyncComponent(() =>
  import("@/views/AuditReports/parking-violation-lookup")
);
const OutcallLookup = defineAsyncComponent(() =>
  import("@/views/AuditReports/outcall-lookup")
);
const GarageAvailabilityStatus = defineAsyncComponent(() =>
  import("@/views/AuditReports/garage-availability-status")
);
const NewUser = defineAsyncComponent(() =>
  import("@/views/UserManagement/new-user")
);
const ManageUser = defineAsyncComponent(() =>
  import("@/views/UserManagement/manage-user")
);
const PasswordReset = defineAsyncComponent(() =>
  import("@/views/UserManagement/password-reset")
);
const PermissionsManagement = defineAsyncComponent(() =>
  import("@/views/UserManagement/permissions-management")
);
const PEOTracker = defineAsyncComponent(() =>
  import("@/views/UserManagement/peo-tracker")
);
const PEOReportingView = defineAsyncComponent(() =>
  import("@/views/UserManagement/peo-reporting-view")
);

const PEOReportingDetails = defineAsyncComponent(() =>
  import("@/views/UserManagement/peo-reporting-detail")
);

const PEOReportingForm = defineAsyncComponent(() =>
  import("@/views/UserManagement/peo-reporting-form")
);

const VtpReport = defineAsyncComponent(() =>
  import("@/views/AuditReports/vtp-report")
);

const routes = [
  {
    path: "/",
    redirect: Login,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "",
    name: "Main",
    component: MainView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/dashboard/:type/:subType",
        name: "Dashboard Details",
        component: DashboardDetails,
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
      },
      {
        path: "/permit-renewals",
        name: "Permit Renewals",
        component: PermitRenewals,
      },
      {
        path: "/expired-permits",
        name: "Expired Permits",
        component: ExpiredPermits,
      },
      {
        path: "/pending-applications",
        name: "Pending Applications",
        component: PendingApplication,
      },
      {
        path: "/rejected-applications",
        name: "Rejected Applications",
        component: RejectedApplication,
      },
      {
        path: "/garage-alerts",
        name: "Garage Alerts",
        component: GarageAlerts,
      },
      {
        path: "/resident-account",
        name: "Resident Account",
        component: ResidentAccount,
      },
      {
        path: "/business-account",
        name: "Business Account",
        component: BusinessAccount,
      },
      {
        path: "/temporary-account",
        name: "Temporary Account",
        component: TemporaryAccount,
      },
      {
        path: "/pending-account",
        name: "Pending Account",
        component: PendingAccount,
      },
      {
        path: "/parking-permits",
        name: "Parking Permits",
        component: ParkingPermits,
      },
      {
        path: "/garage-permits",
        name: "Garage Permits",
        component: GaragePermits,
      },
      {
        path: "/business-permits",
        name: "Business Permits",
        component: BusinessPermits,
      },
      {
        path: "/TNP-signs",
        name: "TNP Signs",
        component: TNPSigns,
      },
      {
        path: "/TNP-sign-map",
        name: "TNP Sign Map",
        component: TNPSignMap,
      },
      {
        path: "/residents",
        name: "Residents",
        component: Residents,
      },
      {
        path: "/business",
        name: "Business",
        component: Business,
      },
      {
        path: "/parking-enforcement",
        name: "Parking Enforcement",
        component: ParkingEnforcement,
      },
      {
        path: "/permit-lookup",
        name: "Permit Lookup",
        component: PermitLookup,
      },
      {
        path: "/ALPR-lookup",
        name: "ALPR Lookup",
        component: ALPRLookup,
      },
      {
        path: "/tnp-outcall",
        name: "TNP Outcall",
        component: TNPOutcall,
      },
      {
        path: "/tnp-sign-lookup",
        name: "TNP Sign Lookup",
        component: TNPSignLookup,
      },
      {
        path: "/peo-assignment",
        name: "PEO Assignment",
        component: PEOAssignment,
      },
      {
        path: "/tnp-violations",
        name: "TNP Violations",
        component: TNPViolations,
      },
      {
        path: "/vtp-activation",
        name: "VTP Activation",
        component: VTPActivation,
      },
      {
        path: "/emergency-parking",
        name: "Emergency Parking",
        component: EmergencyParking,
      },
      {
        path: "/find-peo-location",
        name: "Find Peo Location",
        component: FindPEOLocation,
      },
      {
        path: "/parking-payments-lookup",
        name: "Parking Payments Lookup",
        component: ParkingPaymentsLookup,
      },
      {
        path: "/peo-search-history-lookup",
        name: "PEO Search History Lookup",
        component: PEOSearchHistoryLookup,
      },
      {
        path: "/parking-violation-lookup",
        name: "Parking violation Lookup",
        component: ParkingViolationLookup,
      },
      {
        path: "/outcall-lookup",
        name: "Outcall Lookup",
        component: OutcallLookup,
      },
      {
        path: "/garage-availability-status",
        name: "Garage Availability Status",
        component: GarageAvailabilityStatus,
      },
      {
        path: "/new-user",
        name: "New User",
        component: NewUser,
      },
      {
        path: "/manage-user",
        name: "Manage User",
        component: ManageUser,
      },
      {
        path: "/password-reset",
        name: "Password Reset",
        component: PasswordReset,
      },
      {
        path: "/permissions-management",
        name: "Permissions Management",
        component: PermissionsManagement,
      },
      {
        path: "/peo-tracker",
        name: "PEO Tracker",
        component: PEOTracker,
      },
      {
        path: "/peo-reporting-view",
        name: "PEO Reporting View",
        component: PEOReportingView,
      },
      {
        path: "/peo-reporting/:type",
        name: "PEO Reporting Detail",
        component: PEOReportingDetails,
      },
      {
        path: "/peo/reporting/:type",
        name: "PEO Reporting Form",
        component: PEOReportingForm,
      },
      {
        path: "/vtp-report",
        name: "VTP Report",
        component: VtpReport
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("access_token") == null) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    if (to.path === "/" || to.path === "/login") {
      if (localStorage.getItem("access_token") != null) {
        console.log("redirect dashboard ");
        next({
          path: "/dashboard",
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
